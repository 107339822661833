import { defineStore } from 'pinia';
import type { SystemModel } from '@/domain/system.model';

export const useSystemStore = defineStore('systemStore', {
  state: () => {
    return {
      active_basic: true,
      active_express: true
    };
  },
  actions: {
    setConfiguration({ active_basic, active_express }: SystemModel) {
      this.active_basic = active_basic;
      this.active_express = active_express;
    }
  }
});
