import { SystemModel } from '@/domain/system.model';
import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';

export class SystemRepository extends AxiosFactory {
  constructor() {
    super('/system');
  }

  async getConfiguration(): Promise<CustomAxiosResponse<SystemModel>> {
    return await this.axiosApi.get('');
  }
}
