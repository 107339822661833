// @ts-nocheck
export default {
  install: (app, options) => {
    function ownGtag() {
      window.dataLayer = window.dataLayer || [];
      //eslint-disable-next-line
      window.dataLayer.push(arguments);
    }

    if (typeof window !== 'undefined' && options.property_id !== 'undefined' && options.conversion_track_id) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//www.googletagmanager.com/gtag/js?id=${options.property_id}`;
      script.async = true;
      document.head.appendChild(script);
      const externalScript = document.createElement('script');
      externalScript.setAttribute('id', 'ads-conversions-data');
      document.head.appendChild(externalScript);

      ownGtag('js', new Date());
      ownGtag('config', options.property_id, { allow_enhanced_conversions: true });
      app.config.globalProperties.$ownGtagAds = {
        conversionV2: (email: string, valor: number) => {
          const elem = document.getElementById('ads-conversions-data');
          elem.innerText = `var enhanced_conversion_data = {"email": "${email}"}`;
          ownGtag('event', 'conversion', {
            send_to: options.conversion_track_id,
            value: valor,
            currency: 'EUR',
            transaction_id: ''
          });
        }
      };

      app.provide('$ownGtagAds', app.config.globalProperties.$ownGtagAds);
    } else {
      console.log('g_ads_plugin: error not provided property_id');
    }
  }
};
